<template>
  <div class="dictionaries">
    <div class="content">
      <div class="tit">
        <el-button class="box submit" type="primary" plain icon="el-icon-plus" @click="editAndAddMaterial()">新增</el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%;margin-bottom: 20px;"
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
            prop="procuratoratename"
            label="名称">
          </el-table-column>
          <el-table-column
            prop="refname"
            label="简称">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            layout="total, prev, pager, next, jumper"
            :total="total"
            hide-on-single-page>
          </el-pagination>
        </div>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="680px">
        <el-form :model="form">
            <el-form-item label="全称" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="简称" :label-width="formLabelWidth">
                <el-input v-model="form.abbreviation" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="ifMaterialEdit == 1" label="状态" :label-width="formLabelWidth">
                <el-radio-group v-model="form.radio">
                  <el-radio :label="0">市院</el-radio>
                  <el-radio :label="1">县院</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ifMaterialEdit == 1&&form.radio==1" label="所属市院" :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择用户类型">
                  <el-option v-for="item in options" :key="item.id"  :label="item.procuratoratename" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item v-if="ifMaterialEdit == 1" label="所属市院" :label-width="formLabelWidth">
                <el-cascader
                v-model="value"
                :options="options1"
                @change="handleChange"></el-cascader>
            </el-form-item> -->
            <el-form-item v-if="ifMaterialEdit == 0" label="所属市院" :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择用户类型">
                  <el-option v-for="item in options" :key="item.id"  :label="item.procuratoratename" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="makeSure()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAllOrganization,getOneOrganization,getFproname,getOneCountry,addOrganization} from '@/utils/api'
import { Message } from 'element-ui';
export default {
  inject: ["reload"],
  data(){
    return{
      options1: [{
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则',
            children: [{
              value: 'yizhi',
              label: '一致'
            }, {
              value: 'fankui',
              label: '反馈'
            }, {
              value: 'xiaolv',
              label: '效率'
            }, {
              value: 'kekong',
              label: '可控'
            }]
          }, {
            value: 'daohang',
            label: '导航',
            children: [{
              value: 'cexiangdaohang',
              label: '侧向导航'
            }, {
              value: 'dingbudaohang',
              label: '顶部导航'
            }]
          }]
        }, {
          value: 'zujian',
          label: '组件',
          children: [{
            value: 'basic',
            label: 'Basic',
            children: [{
              value: 'layout',
              label: 'Layout 布局'
            }, {
              value: 'color',
              label: 'Color 色彩'
            }, {
              value: 'typography',
              label: 'Typography 字体'
            }, {
              value: 'icon',
              label: 'Icon 图标'
            }, {
              value: 'button',
              label: 'Button 按钮'
            }]
          }, {
            value: 'form',
            label: 'Form',
            children: [{
              value: 'radio',
              label: 'Radio 单选框'
            }, {
              value: 'checkbox',
              label: 'Checkbox 多选框'
            }, {
              value: 'input',
              label: 'Input 输入框'
            }, {
              value: 'input-number',
              label: 'InputNumber 计数器'
            }, {
              value: 'select',
              label: 'Select 选择器'
            }, {
              value: 'cascader',
              label: 'Cascader 级联选择器'
            }, {
              value: 'switch',
              label: 'Switch 开关'
            }, {
              value: 'slider',
              label: 'Slider 滑块'
            }, {
              value: 'time-picker',
              label: 'TimePicker 时间选择器'
            }, {
              value: 'date-picker',
              label: 'DatePicker 日期选择器'
            }, {
              value: 'datetime-picker',
              label: 'DateTimePicker 日期时间选择器'
            }, {
              value: 'upload',
              label: 'Upload 上传'
            }, {
              value: 'rate',
              label: 'Rate 评分'
            }, {
              value: 'form',
              label: 'Form 表单'
            }]
          }, {
            value: 'data',
            label: 'Data',
            children: [{
              value: 'table',
              label: 'Table 表格'
            }, {
              value: 'tag',
              label: 'Tag 标签'
            }, {
              value: 'progress',
              label: 'Progress 进度条'
            }, {
              value: 'tree',
              label: 'Tree 树形控件'
            }, {
              value: 'pagination',
              label: 'Pagination 分页'
            }, {
              value: 'badge',
              label: 'Badge 标记'
            }]
          }, {
            value: 'notice',
            label: 'Notice',
            children: [{
              value: 'alert',
              label: 'Alert 警告'
            }, {
              value: 'loading',
              label: 'Loading 加载'
            }, {
              value: 'message',
              label: 'Message 消息提示'
            }, {
              value: 'message-box',
              label: 'MessageBox 弹框'
            }, {
              value: 'notification',
              label: 'Notification 通知'
            }]
          }, {
            value: 'navigation',
            label: 'Navigation',
            children: [{
              value: 'menu',
              label: 'NavMenu 导航菜单'
            }, {
              value: 'tabs',
              label: 'Tabs 标签页'
            }, {
              value: 'breadcrumb',
              label: 'Breadcrumb 面包屑'
            }, {
              value: 'dropdown',
              label: 'Dropdown 下拉菜单'
            }, {
              value: 'steps',
              label: 'Steps 步骤条'
            }]
          }, {
            value: 'others',
            label: 'Others',
            children: [{
              value: 'dialog',
              label: 'Dialog 对话框'
            }, {
              value: 'tooltip',
              label: 'Tooltip 文字提示'
            }, {
              value: 'popover',
              label: 'Popover 弹出框'
            }, {
              value: 'card',
              label: 'Card 卡片'
            }, {
              value: 'carousel',
              label: 'Carousel 走马灯'
            }, {
              value: 'collapse',
              label: 'Collapse 折叠面板'
            }]
          }]
        }, {
          value: 'ziyuan',
          label: '资源',
          children: [{
            value: 'axure',
            label: 'Axure Components'
          }, {
            value: 'sketch',
            label: 'Sketch Templates'
          }, {
            value: 'jiaohu',
            label: '组件交互文档'
          }]
        }],
        options: [],
        value: [],
        tableData: [],
        currentPage4: 1,
        total:0,
        dialogFormVisible: false,
        form: {
          name: '',
          abbreviation:'',
          type:'',
          radio:0
        },
        formLabelWidth: '120px',
        dialogTitle: '',
			  ifMaterialEdit: 0, // 0表示编辑，1表示新增
        idx:''
    }
  },
  methods: {
    // 获取列表数据
    getorganizationlist(){
      getAllOrganization().then(res =>{
        // console.log(res);
        this.tableData=res.data.data
      })
    },
    handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
    },
    handleClick(row) {
        // console.log(row);
        this.dialogVisible=true
    },
    gohome(){
        this.$router.push({ path: '/home' })
    },
    // 报存
    save(params){
      addOrganization(params).then(res =>{
        // console.log(res);
      })
    },
    makeSure() {
      // console.log('院id',this.form.type);
      if(this.form.type == ''){
        var typeid = 0
      }else{
        var typeid = this.form.type
      }
      let params = {
        id:this.idx,
        procuratoratename: this.form.name,
        refname: this.form.abbreviation,
        fid: typeid,
      }
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {

        // console.log('新增')
        if(this.form.radio !== 0){
          if(this.form.type == ''){
            Message({ message:'请选择所属市院',type:"warning" })
          }else{
            this.save(params)
            this.dialogFormVisible = false
            Message({ message:'新增成功',type:"success" })
            this.reload();
          }
        }else{
          this.save(params)
          this.dialogFormVisible = false
          Message({ message:'新增成功',type:"success" })
          this.reload();
        }
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接
        // console.log('编辑')
        this.dialogFormVisible = false
        this.save(params)
        Message({ message:'修改成功',type:"success" })
        this.reload();
      }
    },
    editAndAddMaterial(row) {
        if (row) {
          this.ifMaterialEdit = 0
          this.dialogFormVisible = true
          this.dialogTitle = '编辑'
          this.idx = row.id
          getOneOrganization({
            proId:row.id
          }).then(res =>{
            // console.log('222',res);
            this.form.abbreviation=res.data.data.refname
            this.form.name=res.data.data.procuratoratename
            this.form.type = res.data.data.fid
            getFproname({
              fid:this.form.type
            }).then(res =>{
              // console.log(res);
              this.options = res.data.data
            })
          })
        } else {
          this.ifMaterialEdit = 1
          this.dialogFormVisible = true
          this.dialogTitle = '新增'
          this.form.name=''
          this.form.abbreviation=''
          this.form.type=''
          this.form.radio = 0
          this.getproname()
          this.idx = ''
        }
    },
    // 获取市院
    getproname(){
      getOneCountry().then(res =>{
        this.options = res.data.data
      })
    }
  },
  mounted(){
    this.getorganizationlist()
  }
}
</script>

<style  lang="less" scoped>
.dictionaries{
  // height: 95%;
  min-height: 95%;
  background: #fff;
}
.content{
  background: #fff;
  padding: 24px;
  .tit{
    display: flex;
    .box{
      margin-right: 24px;
      // height: 36px;
    }
    .one{
      width: 140px;
    }
    .two{
      width: 189px;
    }
    .four{
      width: 130px;
    }
    .five{
      width: 287px;
    }
    .submit{
      width: 94px;
    }
  }
  .table{
    margin-top: 25px;
    .block{
      margin-top: 20px;
    }
  }
}
.el-pagination {
    text-align: center; 
}
.el-table th.el-table__cell>.cell{
  text-align: center !important;
}
.el-table--enable-row-transition .el-table__body td.el-table__cell{
  text-align: center !important;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #D8D8D8;
}
::v-deep .el-input__inner {
  width: 450px !important;
}
</style>